html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  position: relative;
  text-align: center;
}

h1 {
  text-shadow: 1px 1px 5px black;
}

/* The ultimate sticky footer solution */
/* https://css-tricks.com/snippets/css/sticky-footer/ */
#body-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -60px; 
}

#body-wrap:after {
  content: "";
  display: block;
}

.about-link {
  cursor: pointer;
}

footer, #body-wrap:after {
  height: 60px; 
}

footer p a {
  text-decoration: none;
}
/*Mobile View*/
@media only screen and  (max-width: 760px) {
  .w3-table td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .w3-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .w3-table tr {
    border: 1px solid #E74C3C;
  }

  .w3-table tr + tr {
    margin-top: 1.5em;
  }

  .w3-table td, .w3-table td:first-child {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    background-color: #F8D9D5;
    text-align: left;
  }

  .w3-table td:before {
    content: attr(data-label);
    display: inline-block;
    line-height: 1.5;
    margin-left: -100%;
    width: 75%;
    white-space: nowrap;
  }

  .w3-table td select {
    width: 70%;
  }
}

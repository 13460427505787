/*Mobile View*/
@media only screen and  (max-width: 760px) {
  .w3-table td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .w3-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .w3-table tr {
    border: 1px solid #E74C3C;
  }

  .w3-table tr + tr {
    margin-top: 1.5em;
  }

  .w3-table td, .w3-table td:first-child {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    background-color: #F8D9D5;
    text-align: left;
  }

  .w3-table td:before {
    content: attr(data-label);
    display: inline-block;
    line-height: 1.5;
    margin-left: -100%;
    width: 75%;
    white-space: nowrap;
  }

  .w3-table td select {
    width: 70%;
  }
}
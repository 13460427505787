.App {
  height: 100%;
  position: relative;
  text-align: center;
}

h1 {
  text-shadow: 1px 1px 5px black;
}

/* The ultimate sticky footer solution */
/* https://css-tricks.com/snippets/css/sticky-footer/ */
#body-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -60px; 
}

#body-wrap:after {
  content: "";
  display: block;
}

.about-link {
  cursor: pointer;
}

footer, #body-wrap:after {
  height: 60px; 
}

footer p a {
  text-decoration: none;
}